// ./src/components/ContactUs.js
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container as BaseContainer, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import phoneIconImage from "images/fyinnovea/call_us.svg";
import addressIconImage from "images/fyinnovea/address_icon.svg";
import emailIconImage from "images/fyinnovea/mail_icon.svg";
import linkedInIconImage from "images/fyinnovea/linkedin_icon.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

const Container = styled(BaseContainer)`
  ${tw`relative bg-cover bg-center bg-no-repeat p-8`}
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.8), rgba(185, 175, 230, 0.8));
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const Heading = styled.h1`
  ${tw`font-bold leading-tight text-blue-950 text-center`}
  font-size: 1.85rem;  // Setting the font size to 1.85 rem
`;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const FourColumnContainer = styled.div`
  ${tw`mt-3 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/4 max-w-xs mb-6 lg:mb-0`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center text-center h-full mx-2 px-4 py-6 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 bg-gray-100 shadow-lg`}
  ${tw`border-2 border-l-blue-300`}
  .imageContainer {
    ${tw`flex-shrink-0 rounded-full p-4 bg-gray-100 mb-4`}
    img {
      ${tw`w-20 h-20`}
    }
  }

  .content {
    ${tw``}
  }

  .title {
    ${tw`font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-2 text-sm font-medium text-secondary-300`}
  }

  .phoneList {
    ${tw`text-sm font-medium text-secondary-300`}
    list-style-type: none;
    padding: 0;
  }

  .link {
    ${tw`mt-2 inline-flex items-center text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;
export default ({
  cards = [
    {
      imageSrc: addressIconImage,
      description: "Door No. 8, 14th Main, 14th Cross, H.S.R 4th Sector, opposite to BDA Complex, Bengaluru, Karnataka 560102",
    },
    {
      imageSrc: phoneIconImage,
      description: ["+91 99860 12816", "+91 99234 48218", "+91 098531 73532", "+91 098597 97154"],
    },
    {
      imageSrc: emailIconImage,
      description: "contactus@fyinnovea.com",
    },
    {
      imageSrc: linkedInIconImage,
      url: "https://www.linkedin.com/company/fyinnovea-research-analytics/"
    }
  ],
  linkText = "Learn More",
  heading = "Contact Us",
  subheading = "",
  description = "",
  imageContainerCss = null,
  imageCss = null
}) => {
  /*
   * This component accepts a prop - `cards` which is an array of objects denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) description - the description of the card
   *  3) url - the url that the card should goto on click
   */
  return (
    <Container id="contactus">
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        {heading && <Heading>{heading}</Heading>}
        {description && <Description>{description}</Description>}
        <FourColumnContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card href={card.url}>
                <div className="imageContainer" css={imageContainerCss}>
                  <img src={card.imageSrc} alt="" css={imageCss} />
                </div>
                <div className="content">
                  <span className="title">{card.title}</span>
                  {Array.isArray(card.description) ? (
                    <ul className="phoneList">
                      {card.description.map((phone, index) => (
                        <li key={index}>{phone}</li>
                      ))}
                    </ul>
                  ) : (
                    <p className="description">{card.description}</p>
                  )}
                  {card.url && (
                    <span className="link">
                      <span>{linkText}</span>
                      <ArrowRightIcon className="icon" />
                    </span>
                  )}
                </div>
              </Card>
            </Column>
          ))}
        </FourColumnContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
