import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import ContactUS from "components/features/ThreeColSimple.js";
import heroScreenshotImageSrc from "images/fyinnovea/fynbullion.png";
import macHeroScreenshotImageSrc from "images/fyinnovea/realEstateImage.png";
import prototypeIllustrationImageSrc from "images/fyinnovea/startup.png";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import Header from "components/headers/light.js"; // Make sure to import your header component
export default () => {
  const Heading = tw.h1`mt-4 font-black text-left text-xl sm:text-2xl lg:text-3xl text-center md:text-left leading-tight`;
  const Subheading = tw.span`capitalize tracking-widest font-bold text-primary-500`;
  const Branding = tw.text`text-blue-500 italic text-left text-xs`

  const HighlightedText = tw.span`text-primary-500`;

  return (
    <div>
      <Header/>
      <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <MainFeature2>
        subheading={<Heading><span tw="text-blue-950">FYINNOVEA Startup HUB</span></Heading>}
        heading={
          <span>
          <Branding>
          Vision... Success... Business Excellence.
          </Branding>
          </span>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Expert Guidance",
            description: "Our team of experienced consultants provides expert guidance tailored to your unique business needs, ensuring every step of your entrepreneurial journey is supported.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: BriefcaseIcon,
            title: "Comprehensive Solutions",
            description: "From ideation to execution, we offer comprehensive solutions that cover all aspects of startup development and business consulting, ensuring no detail is overlooked.",
            iconContainerCss: tw`bg-red-300 text-red-800`
          }
        ]}
      </MainFeature2>
      <MainFeature>
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      </MainFeature>
      <FeatureWithSteps
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <ContactUS/>
      <Footer />
    </AnimationRevealPage>
    </div>
  );
}
