import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/light.js";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import DesignIllustration from "../../images/fyinnovea/analyticsHero.gif";
import {Branding, Subheading, SectionSubheading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as lucidDashboardIcon } from "images/fyinnovea/bi-db.svg";
import { ReactComponent as proactiveIcon} from "images/fyinnovea/proactive_icon.svg";
import { ReactComponent as accuratePredictionIcon} from "images/fyinnovea/goal.svg";

// Constants Declaration
const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-start max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-start max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-start`;
const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;
const Features = tw.div`mx-auto md:mx-0 flex flex-col lg:flex-row max-w-xs lg:max-w-none`;
const Feature = tw.div`mt-10 lg:mt-8 flex items-center md:items-start flex-col md:mr-8 last:mr-0`;
const FeatureHeadingContainer = tw.div`flex items-center`;

const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-primary-500 text-center rounded p-2 flex-shrink-0`}
  ${props => [
    props.iconRoundedFull && tw`rounded-full`,
    props.iconFilled && tw`border-0 bg-primary-500 text-gray-100`
  ]}
  svg {
    ${tw`w-10 h-10`}
  }
`;
const FeatureHeading = tw.div`ml-3 font-bold text-xl`;
const FeatureDescription = tw.div`mt-4 text-left md:text-left text-gray-600 leading-relaxed`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-12 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

const row1defaultFeatures = [
  {
    Icon: accuratePredictionIcon,
    title: "Accurate Predictions",
    description: "We apply AI driven predictive analytics to accurately predict the trend and arrive at proactive strategies​",
    iconContainerCss: tw`bg-gray-200 text-teal-800`
  },
  {
    Icon: proactiveIcon,
    title: "Proactive Strategies",
    description: "With AI, organizations can quickly adapt to market changes, aligning their initiatives with evolving strategic objectives",
    iconContainerCss: tw`bg-red-300 text-red-800`
  }
];

const row2defaultFeatures = [
  {
    Icon: lucidDashboardIcon,
    title: "Lucid AI Dashboard",
    description: "Stunning AI powered info graphics, in-depth strategic insights and reporting​",
    iconContainerCss: tw`bg-blue-200 text-teal-800`
  }
];

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-start items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;
const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
  }
`;

//Variable Declaration
let iconRoundedFull = true;
let iconFilled = true;
let iconContainerCss = null;
let features_row1 = row1defaultFeatures;
let features_row2 = row2defaultFeatures;

export default ({ roundedHeaderButton }) => {
  return (
    <>
      <Container id="fyn+">
        <TwoColumn>
          <LeftColumn>
          <IllustrationContainer>
              <img tw="min-w-0 w-full max-w-lg" src={DesignIllustration} alt="Design Illustration" />
            </IllustrationContainer>
          </LeftColumn>
          <RightColumn>
            <Heading>
              <span tw="text-blue-950">FYINNOVEA+</span>
            </Heading>
            <Subheading>
              Portfolio analytics platform by FYINNOVEA 
            </Subheading>
            <Paragraph>
              With FYINNOVEA, you get the best in class research on investment. As the market changes, intelligent analytics empowers  investors to facilitate informed investment decisions through predictive analytics, risk assessment, and market sentiment analysis, by leveraging data-driven insights and advanced analytics 
            </Paragraph>
            <Features>
              {features_row1.map((feature, index) => (
                <Feature key={index}>
                  <FeatureHeadingContainer>
                    <FeatureIconContainer
                      iconFilled={iconFilled}
                      iconRoundedFull={iconRoundedFull}
                      css={feature.iconContainerCss || iconContainerCss}
                    >
                      {<feature.Icon />}
                    </FeatureIconContainer>
                    <FeatureHeading>{feature.title}</FeatureHeading>
                  </FeatureHeadingContainer>
                  <FeatureDescription>{feature.description}</FeatureDescription>
                </Feature>
              ))}
            </Features>
            <Features>
              {features_row2.map((feature, index) => (
                <Feature key={index}>
                  <FeatureHeadingContainer>
                    <FeatureIconContainer
                      iconFilled={iconFilled}
                      iconRoundedFull={iconRoundedFull}
                      css={feature.iconContainerCss || iconContainerCss}
                    >
                      {<feature.Icon />}
                    </FeatureIconContainer>
                    <FeatureHeading>{feature.title}</FeatureHeading>
                  </FeatureHeadingContainer>
                  <FeatureDescription>{feature.description}</FeatureDescription>
                </Feature>
              ))}
            </Features>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};