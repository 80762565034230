import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import LogoImage from "images/fyinnovea/fyinnoveaLogo.png";

const Container = tw.div`relative bg-gray-100 text-gray-100 -mb-8 -mx-8 px-8 py-4 lg:py-6`; // Reduced py-20 to py-12 and lg:py-24 to lg:py-16
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const Divider = tw.div`my-8 border-b-2 border-primary-400 w-full`; // Reduced my-16 to my-8
const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;
const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-40`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-100`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-4 md:mt-0 font-medium text-gray-700`; // Reduced mt-8 to mt-4
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-400 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
export default () => {
  return (
    <Container>
      <Content>
        <Divider />
        <ThreeColRow>
          <LogoContainer>
            <LogoImg src={LogoImage} />
          </LogoContainer>
          <CopywrightNotice>&copy; 2024 Fyinnovea Inc. All Rights Reserved.</CopywrightNotice>
        </ThreeColRow>
      </Content>
    </Container>
  );
};
