import { createGlobalStyle } from 'styled-components'
import  { globalStyles } from 'twin.macro'

const GlobalStyles = createGlobalStyle(globalStyles, `
   /* Below animations are for modal created using React-Modal */
     .ReactModal__Overlay {
     transition: transform 300ms ease-in-out;
     transition-delay: 100ms;
     transform: scale(0);
   }
   .ReactModal__Overlay--after-open{
     transform: scale(1);
   }
   .ReactModal__Overlay--before-close{
     transform: scale(0);
   }
   nav.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 50;
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`)

export default GlobalStyles